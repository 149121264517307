import { useMemo } from 'react'
import { EMBED_CHANGE_CARD_BILLING_ADDRESS_DETAILS as content } from '@genoa/screen-content'
import { Box, Button, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { BillingAddress } from '../../../../../../modules/flex2/billing'
import { BankShield } from '../../../../../assets'
import { ExtraSmallText, PrimaryButton, SmallText } from '../../../../../components'

type BillingAddressDetailsProps = {
  billingAddressInfo: BillingAddress
  billingAddressError?: string
  isProcessingRegisterCard: boolean
  onPressAddCTA: () => void
  onPressChangeBillingAddress: () => unknown
}

export const BillingAddressDetails = (props: BillingAddressDetailsProps) => {
  const { city, name, state, streetAddress, unit, zip } = props.billingAddressInfo

  const cityStateZip = useMemo(() => {
    let address = ''

    if (city) {
      address = `${city}, `
    }
    if (state) {
      address = `${address}${state} `
    }
    if (zip) {
      address = `${address}${zip}`
    }

    return address
  }, [props.billingAddressInfo])

  return (
    <>
      <SmallText>{content.HEADER}</SmallText>

      <ChangeBillingAddressContainer>
        <Flex flexFlow="column">
          <SmallText>{name}</SmallText>
          <SmallText>{streetAddress}</SmallText>
          {unit && <SmallText>{unit}</SmallText>}
          <SmallText>{cityStateZip}</SmallText>
        </Flex>
        <Button onClick={props.onPressChangeBillingAddress} variant="small" w="84px">
          {content.CTA_CHANGE_BILLING_ADDRESS}
        </Button>
      </ChangeBillingAddressContainer>

      <ErrorBox visibility={!!props.billingAddressError ? 'visible' : 'hidden'}>
        <SmallText hasError={!!props.billingAddressError}>{props.billingAddressError}</SmallText>
      </ErrorBox>

      <Box minH="52px" />

      <DisclaimerContainer>
        <IconWrapper>
          <BankShield />
        </IconWrapper>
        <DisclaimerText>{content.DISCLAIMER}</DisclaimerText>
      </DisclaimerContainer>

      <Box />
      <Box minH="20px" />

      <PrimaryButton
        disabled={props.isProcessingRegisterCard}
        onClick={props.onPressAddCTA}
        processing={props.isProcessingRegisterCard}
      >
        {content.CTA_CONFIRM_CARD}
      </PrimaryButton>
    </>
  )
}

const ChangeBillingAddressContainer = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.wildflower};
  border: 1px solid ${({ theme }) => theme.colors.storm};
  border-radius: ${({ theme }) => theme.radii.lg};
  justify-content: space-between;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const IconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.fixedSizes.spacing_62_5};
`

const DisclaimerContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
`

const DisclaimerText = styled(ExtraSmallText)`
  padding-top: ${({ theme }) => theme.fixedSizes.spacing_12_5};
`

const ErrorBox = styled(Box)`
  color: ${({ theme }) => theme.colors.error};
`
