import { useMemo } from 'react'
import { AddressState } from '@genoa/state-management'

import { useReduxSelector } from '../../../../../../hooks'
import { useAccount } from '../../../../../../hooks/use-account'
import { RootState } from '../../../../../../modules'
import { useUserAccount } from '../../../../../../providers'

export const useBillingAddressDetails = () => {
  const addressState: AddressState = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.address)
  const billingAddress = useReduxSelector(({ billing }: RootState) => billing.address)
  const { userAccount } = useUserAccount()
  const { customer } = useAccount()
  const address = customer?.address

  const userAddress = useMemo(() => {
    if (billingAddress.zip && billingAddress.city && billingAddress.state && billingAddress.streetAddress) {
      return {
        streetAddress: billingAddress.streetAddress,
        ...(billingAddress.unit && { unit: billingAddress.unit }),
        city: billingAddress.city,
        name: billingAddress.name,
        state: billingAddress.state,
        zip: billingAddress.zip,
      }
    }

    if (addressState.zipCode && addressState.city && addressState.state && addressState.street) {
      return {
        streetAddress: addressState.street,
        unit: addressState.unit,
        city: addressState.city,
        state: addressState.state,
        zip: addressState.zipCode,
      }
    }

    if (address) {
      return {
        streetAddress: address.line_1,
        unit: address.line_2,
        city: address.city,
        state: address.state,
        zip: address.zip_code,
      }
    }

    return {
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
    }
  }, [address, addressState, billingAddress])

  const userName = useMemo(() => {
    if (!userAccount.firstName || !userAccount.lastName) {
      return ''
    }

    const firstName = userAccount.firstName.charAt(0).toUpperCase() + userAccount.firstName.slice(1) || ''
    const secondName = userAccount.lastName.charAt(0).toUpperCase() + userAccount.lastName.slice(1) || ''

    return `${firstName} ${secondName}`
  }, [userAccount.firstName, userAccount.lastName])

  const normalizedAddress = useMemo(() => ({ name: userName, ...userAddress }), [userAddress, userName])

  return normalizedAddress
}
