import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { OnboardingStep } from '@genoa/middle-end'
import { StripeCardElement } from '@stripe/stripe-js'

import { useReduxAction } from '../../../../../../hooks'
import { useOnboardingStatus } from '../../../../../../hooks/flex2/onboarding-status'
import { useSecureLineOfCredit } from '../../../../../../hooks/secure-line-of-credit'
import { setBillingAddressAction } from '../../../../../../modules/flex2/billing'
import { useAnalytics, useFees } from '../../../../../../providers'
import { useContent } from '../../../../../../providers/content'
import * as Routes from '../../../../../../routing/constants'
import { BillingAddress } from './billing-address-types'
import { useRegisterCardV2 } from './hooks/use-register-card-v2'
import { RegisterCard } from './RegisterCard'

export type RegisterCardContainerNavigationParams = {
  analyticsScreenName:
    | Analytics.Screens.ADD_CARD_ONBOARDING_ADD_CARD
    | Analytics.Screens.ADD_CARD_ONBOARDING_CONFIRM_CARD
  nextRoute: string
}

export const RegisterCardContainer = () => {
  const {
    content: { REGISTER_CARD },
    setUserContentRule,
  } = useContent()

  const [cardElement, setCardElement] = useState<StripeCardElement | null>()
  const [isCardFormFilled, setIsCardFormFilled] = useState(false)
  const [cardFormError, setCardFormError] = useState('')
  const [billingAddressError, setBillingAddressError] = useState('')
  const location = useLocation()
  const { processingFeePercentage } = useFees()
  const [startedTyping, setStartedTyping] = useState(false)
  const { resolveNextStep } = useOnboardingStatus()
  const { isPricingEnabled, isADO } = useSecureLineOfCredit()
  const setBillingAddress = useReduxAction(setBillingAddressAction)

  const navigate = useNavigate()
  const { startRegisterCard, isLoading } = useRegisterCardV2({
    onSuccess: () => {
      if (isADO) {
        return navigate(Routes.Onboarding.OFFER_DETAILS)
      }
      resolveNextStep(OnboardingStep.PAYMENT_METHOD_SETUP, () =>
        navigate(location?.state?.nextRoute || Routes.Onboarding.SOFT_CREDIT_CHECK)
      )
    },
  })
  const analytics = useAnalytics()

  useEffect(() => {
    if (isPricingEnabled) {
      setUserContentRule('REGISTER_CARD_SLC_PRICING_ENABLED_CONTENT')
    }
  }, [isPricingEnabled])

  useEffect(() => {
    if (location?.state?.analyticsScreenName) {
      analytics.logScreenView(location.state.analyticsScreenName)
    }
  }, [])

  const handleCardChange = useCallback(
    (isCompleted: boolean) => {
      if (!startedTyping) {
        analytics.logEvent(Analytics.Events.STARTED_TYPING_REGISTER_CARD)
        setStartedTyping(true)
      }
      setIsCardFormFilled(isCompleted)
      setCardFormError('')
    },
    [isCardFormFilled, cardFormError, startedTyping]
  )

  const handlePressAddCTA = useCallback(
    (billingAddress: BillingAddress) => {
      analytics.logEvent(Analytics.Events.ADD_CARD_CTA_CLICKED)

      const isMissingBillingAddress =
        !billingAddress.zip || !billingAddress.streetAddress || !billingAddress.state || !billingAddress.city

      if (!billingAddress.name) {
        setBillingAddressError('Please include a name with your billing information')
      } else if (isMissingBillingAddress) {
        setBillingAddressError('Billing information is a required field')
      } else {
        setBillingAddressError('')
      }

      if (!isCardFormFilled || !cardElement) {
        setCardFormError('Card number is a required field')
        return
      }

      if (isMissingBillingAddress || !billingAddress.name) {
        return
      }

      setBillingAddress(billingAddress)
      setCardFormError('')
      startRegisterCard(billingAddress, cardElement)
    },
    [startRegisterCard, isCardFormFilled, cardElement]
  )

  return (
    <RegisterCard
      onCardInputReady={setCardElement}
      onPressAddCTA={handlePressAddCTA}
      onCardChange={handleCardChange}
      cardFormError={cardFormError}
      billingAddressError={billingAddressError}
      isLoading={isLoading}
      processingFeePercentage={processingFeePercentage}
      bodyCopy={REGISTER_CARD.BODY}
    />
  )
}
