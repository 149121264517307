import { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { RightArrowCircle } from '../../../../../assets'
import { Gap, SmallText } from '../../../../../components'

export interface PaymentRow {
  leftSide: string | ReactNode
  rightSide?: string | ReactNode
}

interface PaymentBoxProps {
  header: string
  rows: PaymentRow[]
  children?: ReactNode
}

export const PaymentBox = ({ children, header, rows }: PaymentBoxProps) => {
  const theme = useTheme()

  return (
    <PaymentBoxContainer>
      <PaymentHeader>
        <RightArrowCircle />
        <WrapText fontWeight="bold">{header}</WrapText>
      </PaymentHeader>

      <Gap size="spacing_100" />

      {rows.map((row, index) => (
        <PaymentRow key={index}>
          <Flex color={theme.colors.dusk} fontWeight="bold">
            {row.leftSide}
          </Flex>
          {!!row.rightSide && <Flex>{row.rightSide}</Flex>}
        </PaymentRow>
      ))}

      {children && <PaymentRow>{children}</PaymentRow>}
    </PaymentBoxContainer>
  )
}

const PaymentBoxContainer = styled(Flex)`
  border-radius: ${({ theme }) => theme.radii.xl};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  margin: ${({ theme }) => theme.fixedSizes.spacing_12_5};
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const PaymentHeader = styled(Flex)`
  align-items: center;
  flex-direction: row;
`

const PaymentRow = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.wildflower};
  border-radius: ${({ theme }) => theme.radii.lg};
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_50};
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const WrapText = styled(SmallText)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_100};
`
